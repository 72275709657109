/* IPhoneMock.css */
.iphone-mock-container {
  width: 150px;
  height: 300px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iphone-mock {
  transform: scale(0.6);
  transform-origin: center center;
  width: 300px;
  height: 600px;
}

.content-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .iphone-mock-container {
    width: 120px;
    height: 240px;
  }

  .iphone-mock {
    transform: scale(0.48);
  }
}

@media (max-width: 480px) {
  .iphone-mock-container {
    width: 90px;
    height: 180px;
  }

  .iphone-mock {
    transform: scale(0.36);
  }
}