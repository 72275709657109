.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #FFFFF0, #E6F3FF);
  color: #1E40AF;
}

.content {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  padding: 2rem;
}

.title {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #1E40AF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #0369A1;
}

.feature-container {
  margin-right: 2rem;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8rem;
  flex-wrap: wrap;
}

.signup-container {
  width: 100%;
  margin-top: 12rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.form {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.input {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: 1px solid #4299E1;
  width: 250px;
}

.button {
  background-color: #4299E1;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footer {
  font-size: 0.9rem;
  color: #1E40AF;
  text-align: center;
}

.footer-link {
  color: #2563EB;
  text-decoration: none;
  margin: 0 0.5rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .feature-container {
    gap: 2rem;
  }

  .input {
    width: 100%;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .feature-container {
    gap: 1rem;
  }

  .form {
    flex-direction: column;
    align-items: center;
  }

  .button {
    width: 100%;
    max-width: 250px;
  }
}